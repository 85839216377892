<template>
  <div class="member-committee">
    <p>{{ title }}</p>
    <div class="member-committee__members">
      <card-member-board v-for="(member, key) in members" :templated="member" :key="`member_${key}`"
          :class="{'pointer': member.expert}"/>
    </div>
  </div>
</template>

<script>
import CardMemberBoard from '@/components/indexPage/CardMemberBoard'

export default {
  name: 'MemberCommittee',
  props: {
    title: {
      type: String,
      default: ''
    },
    members: {
      type: Array,
      required: true
    }
  },
  components: {
    'card-member-board': CardMemberBoard
  }
}
</script>

<style scoped lang="scss">
  h2 {
    margin-bottom: 1.25rem;
  }

  .member-committee {
    font-size: .875rem;
    line-height: 1.57;

    & p {
      margin-bottom: 1.375rem;
      margin-top: .625rem;
      font-weight: 600;
    }

    &__members {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.125rem;

      div{
        margin: 0 1.125rem 1.875rem;
        width: calc(25% - 1.125rem - 1.125rem);
        cursor: pointer;
      }
    }
  }
  .pointer{
    // pointer-events: none;
  }
  @media screen and (max-width: 768px) {
    .member-committee {
      display: flex;
      overflow: hidden;

     &__members {
        flex-wrap: nowrap;
        margin: 0 0 0 1.125rem;
        > div {
          margin: 0 .4rem 1.875rem !important;
          width: 12.1875rem;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .member-committee {
      flex-direction: column;
      overflow-x: hidden !important;

     &__members {
        margin-left: 0;
        overflow-x: auto;
        > div {
          min-width: 12.1875rem;
          margin: 0 .8rem 1.875rem 0 !important;
        }
      }
    }
    ::v-deep .white-container  p {
        width: 100%;
      }
  }

</style>
