<template>
  <div>
    <ul class="work-group_list">
      <li v-for="(item, key) in content" :key="`member${key}`">
        <router-link :to="`/structure_list/${item.id}`">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WorkingGroups',
  props: {
    content: {
      type: Array,
      require: false
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.$store.dispatch('apiGetWorkingGroupList', this.id)
  },
  computed: {
    GET_WORK_GROUP () {
      return this.$store.getters.GET_WORK_GROUP
    }
  }
}
</script>

<style scoped lang="scss">
  .work-group_list {

    li {
      font-size: 0.875rem;
      line-height: 1.50;
      color: #246CB7;
      margin: 0.75rem 0 0.75rem 1.1rem;
      list-style-type: disc;
      cursor: pointer;
    }
    a {
      color: #246CB7;
    }

  }

</style>
