<template>
  <div v-if="getListNews.length">
    <!--<section v-if="getListNews.length === 0" class="news-block">
      <article class="news-content">
        <header class="no-content">
          <h1>{{ $store.getters.GET_LANG ? 'Новости отсутствуют' : 'No news available' }}</h1>
        </header>
      </article>
    </section>-->
    <router-link :to="`/news/${newsBlock.id}`" v-for="(newsBlock, i) in getListNews" :key="i" class="news-block">
      <div class="news-info">
        <!--<p v-for="(tag, index) in newsBlock.tags_list" :key="index" class="news-info__tags">
          {{ tag }}
        </p>-->
        <p class="news-info__date">
          {{ toLocateMonth(newsBlock.date_time) }}
        </p>
      </div>
      <article class="news-content">
        <header>
          <h1>{{ $store.getters.GET_LANG ? newsBlock.title : newsBlock.title_eng }}</h1>
        </header>
        <p>
          {{ newsBlock.description }}
        </p>
        <!--<p class="news-content__rubric">Рубрика: <span> {{  `rubric_model` in newsBlock ?  newsBlock['rubric_model'].title : 'Отсутстует' }}</span>
        </p>-->
      </article>
    </router-link>
    <pagination v-if="getListNews.length !== 0" class="news__pagination"
                :per-page="$store.getters.getPaginationLimit"
                :current="$store.getters.getPaginationCurrentPage"
                :total="$store.getters.getPaginationTotal"
                @page-changed="getPage"/>
    <!--    <pagination-block class="pagination" :current="currentPage" :total="countPage" @page-changed="getPage"/>-->
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'

export default {
  name: 'News',
  props: {
    tagNews: {
      type: Array
    }
  },
  components: {
    Pagination
  },
  data () {
    return {
      currentPage: 1,
      countPage: 1
    }
  },
  mounted () {
    if (this.$rotue.name === 'MemberProfile') {
      return
    }
    this.getData()
  },
  beforeDestroy () {
    this.$store.commit('setPaginationLimit', 9)
  },
  computed: {
    getListNews () {
      return this.$store.getters.getPublicNews
    }
  },
  methods: {
    getPage (page) {
      // console.log(page)
      this.$store.commit('setPaginationLimit', 3)
      this.$store.commit('setPaginationOffset', (page - 1) * this.$store.getters.getPaginationLimit)
      this.$emit('getPaginatedNews')
      // this.getData()
    },
    toLocateMonth (date) {
      return (new Date(date).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })).slice(0, -3)
    },
    getData () {
      this.$store.dispatch('getPublicNews')

      if (this.tagNews) {
        // this.$store.commit('setFilterTag', this.tagNews.join(','))

        this.$nextTick(() => {
          // this.$store.dispatch('getAllCommitteeNews')
        })
      } else {
        if (this.$store.getters.GET_MEMBER.firstName !== 'undefined') {
          // this.$store.dispatch('getAllChambersNews', `${this.$store.getters.GET_MEMBER.firstName} ${this.$store.getters.GET_MEMBER.lastName}`)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: rgba(31, 50, 69, 0.8);
    margin-bottom: 1.375rem;
  }

  .news-block {
    padding: 1.25rem 1.875rem;
    border: 1px solid #EBF3FF;
    box-shadow: 0 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;

  }

  .news-content {
    font-style: normal;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.57;
    color: rgba(23, 23, 23, 0.8);
    width: 80%;

    & h1 {
      font-weight: 600;
      color: #1F3245;
      margin-bottom: .625rem;
    }

    & p {
      margin-bottom: .625rem;

      & span {
        color: #246CB7;
        font-weight: 600;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }

  }

  .no-content {
    > h1 {
      margin: 0;
    }
  }

  .news-info {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    /*flex-wrap: wrap;*/
    /*width: 100%;*/
    /*margin-left: auto;*/

    &__tags {
      font-weight: 600;
      font-size: .8125rem;
      line-height: 1.23;
      text-transform: uppercase;
      padding: .125rem .625rem;
      margin-bottom: 1.25rem;
      border-radius: 3px;
      color: #306392;
      background: #EEF4FA;
      margin-right: .625rem;
    }

    &__date {
      padding: .125rem .625rem;
      color: rgba(23, 23, 23, 0.8);
      font-size: .75rem;
      line-height: 1.5;

    }
  }

  .pagination {
    margin-top: 2.5rem;
  }

  @media screen and (max-width: 768px) {
    .news-content {
      width: 96%;
    }
  }

  @media screen and (max-width: 420px) {
    .news-content {
      width: 100%;
    }
    .news-block {
      padding: 1.25rem 1.38rem;
    }
  }
</style>
